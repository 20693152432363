import { AirportDb } from "@/components/FlightInfo/AirportDb";
import { Airport } from "@/components/FlightInfo/FlightInfoApi";

export interface RouteForProcess {
  departureIata: string;
  interIatas: string[];
  arrivalIata: string;
}

export interface RouteForApi {
  departureAirport: Airport;
  arrivalAirport: Airport;
}

export class RouteCalculator {
  constructor(private airportDb: AirportDb) {}

  getRoutesForApi(airline: string, countryCodes: string[]) {
    const budRoutes = this.airportDb.getBudRoutes(airline);
    const routes = new Map<string, RouteForApi>();

    //Direct routes
    budRoutes.forEach((route) => {
      const key = "BUD" + "_" + route;
      const departureAirport = this.airportDb.getBud();
      const arrivalAirport = this.airportDb.findOne(route);
      if (!departureAirport || !arrivalAirport) {
        console.error("Airport not found", airline, "BUD", departureAirport, route, arrivalAirport);
        return;
      }
      routes.set(key, { departureAirport, arrivalAirport });
    });

    //Indirect routes
    const destAirportIatas = this.airportDb.findAllIataByCountryCode(countryCodes);
    const interAirports = this.airportDb.getBudInterAirports(airline);

    for (const interAirport of interAirports) {
      const interRoutes = this.airportDb.getRoutes(interAirport, airline);
      interRoutes
        .filter((interRoute) => destAirportIatas.includes(interRoute))
        .forEach((interRoute) => {
          const switchedKey = interRoute + "_" + interAirport.iata;
          if (routes.has(switchedKey)) {
            return;
          }
          const key = interAirport.iata + "_" + interRoute;
          const arrivalAirport = this.airportDb.findOne(interRoute);
          if (!arrivalAirport) {
            console.error("Airport not found", airline, interRoute, arrivalAirport);
            return;
          }
          routes.set(key, { departureAirport: interAirport, arrivalAirport });
        });
    }
    return [...routes.values()] ?? [];
  }

  getRoutes(countryCodes: string[]) {
    const routes = new Map<string, RouteForProcess>();
    const destAirportIatas = this.airportDb.findAllIataByCountryCode(countryCodes);

    //Direct
    this.airportDb
      .getAllBudRoutes()
      //.filter((budRoute) => destAirportIatas.includes(budRoute))
      .forEach((budRoute) => {
        routes.set(budRoute, { departureIata: "BUD", interIatas: [], arrivalIata: budRoute });
      });

    //Inter airport
    const interAirports = this.airportDb.getAllBudInterAirports();
    for (const interAirport of interAirports) {
      this.airportDb
        .getAllRoutes(interAirport)
        .filter((interRoute) => destAirportIatas.includes(interRoute))
        .forEach((interRoute) => {
          if (!routes.has(interRoute)) {
            routes.set(interRoute, { departureIata: "BUD", interIatas: [interAirport.iata], arrivalIata: interRoute });
          } else {
            routes.get(interRoute)?.interIatas?.push(interAirport.iata);
          }
        });
    }

    return routes;
  }
}
