import firebase from "@/plugins/firebase";
import { Airport, Flight, FlightInfoApi } from "@/components/FlightInfo/FlightInfoApi";
import dayjs from "dayjs";

const durationMinutesMap: { [iatas: string]: number } = {
  BUD_AUH: 340,
  AUH_BUD: 365,
  BUD_DXB: 340,
  DXB_BUD: 365,
  AUH_MLE: 320,
  MLE_AUH: 350,
  DXB_MLE: 320,
  MLE_DXB: 350,
};

export class WizzAirApi extends FlightInfoApi {
  airline = "Wizz air";

  getWebsiteUrl(fromIata: string, toIata: string, from: number) {
    const dateFormat = "YYYY-MM-DD";
    const fromDate = dayjs(from).format(dateFormat);
    return (
      "https://wizzair.com/#/booking/select-flight/" +
      fromIata +
      "/" +
      toIata +
      "/" +
      fromDate +
      "/" +
      "null" +
      "/1/0/0/null"
    );
  }

  async getReturnFlights(
    departureAirport: Airport,
    arrivalAirport: Airport,
    fromDate: string
  ): Promise<{ fromFlights: Flight[]; toFlights: Flight[] }> {
    const idToken = (await firebase.getIdToken()) ?? "";

    //NOTE: The flight-info-api also has this +42 days, when the fromDate is in the past
    const toDate = dayjs(fromDate).add(42, "days").format("YYYY-MM-DD");

    const body = {
      flightList: [
        { departureStation: departureAirport.iata, arrivalStation: arrivalAirport.iata, from: fromDate, to: toDate },
        { departureStation: arrivalAirport.iata, arrivalStation: departureAirport.iata, from: fromDate, to: toDate },
      ],
      priceType: "regular",
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
    };
    let data: any;
    // noinspection ExceptionCaughtLocallyJS
    try {
      const response = await fetch("/flight-info-api/wizzair/timetable", {
        headers: {
          "x-auth-token": idToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        method: "POST",
      });
      data = await response.json();
      if (!data.outboundFlights || !data.returnFlights) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error("Invalid response from wizzair");
      }
      this.backOffTime = undefined;
    } catch (e) {
      this.backOffTime = this.backOffTime !== undefined ? this.backOffTime * 2 : this.backOffTimeStart;
      console.log(this.airline, "wait", this.backOffTime);
      await this.wait(this.backOffTime);
      return await this.getReturnFlights(departureAirport, arrivalAirport, fromDate);
    }

    return {
      fromFlights: this.mapFlights(departureAirport, arrivalAirport, data.outboundFlights),
      toFlights: this.mapFlights(arrivalAirport, departureAirport, data.returnFlights),
    };
  }

  private mapFlights(departureAirport: Airport, arrivalAirport: Airport, data: any[]) {
    return data
      .filter((item) => item.priceType !== "checkPrice") //TODO Do something! Prices are missing!
      .map((item) => {
        let priceHuf = 100000;
        const price = item.price.amount;
        const currencyCode = item.price.currencyCode;
        if (item.price.currencyCode !== "HUF") {
          const rate = this.rates[currencyCode];
          if (rate !== undefined) {
            priceHuf = Math.round(price * rate);
          }
        } else {
          priceHuf = price;
        }

        const durationMinutes = durationMinutesMap[departureAirport.iata + "_" + arrivalAirport.iata];

        const departureDate = Date.parse(item.departureDates[0]);
        let arrivalDate: number;
        if (durationMinutes) {
          arrivalDate = departureDate + durationMinutes * 60000;
        } else {
          arrivalDate = departureDate; //TODO Fill in durationMinutesMap manually
        }

        const duration = this.duration(departureDate, arrivalDate);

        const flight: Flight = {
          api: this,
          departureDate: departureDate,
          arrivalDate: arrivalDate,
          departureAirport,
          arrivalAirport,
          duration,
          price: priceHuf,
        };
        return flight;
      });
  }

  async getAirports() {
    const idToken = (await firebase.getIdToken()) ?? "";
    const response = await fetch("/flight-info-api/wizzair/map", {
      headers: {
        "x-auth-token": idToken,
      },
      method: "GET",
    });
    const data: any = await response.json();
    //console.log(this.airline, data);

    const fakeAirportIatas: string[] = data.cities
      .filter((city: any) => city.isFakeStation)
      .map((city: any) => city.iata);

    const airports: Airport[] = [];
    for (const city of data.cities.filter((city: any) => !city.isFakeStation)) {
      const routes = city.connections
        .map((connection: any) => connection.iata)
        .filter((iata: string) => !fakeAirportIatas.includes(iata))
        .filter((iata: string) => !(city.iata === "DXB" && iata === "MLE")); //Wizzair adds this virtually, we need to skip
      const allRoutes = new Map<string, string[]>();
      allRoutes.set(this.airline, routes);

      const airport: Airport = {
        iata: city.iata,
        name: city.shortName,
        cityName: "?",
        countryCode: city.countryCode,
        countryName: city.countryName,
        //latitude: city.latitude,
        //longitude: city.longitude,
        allRoutes,
      };
      airports.push(airport);
    }
    return airports;
  }
}
