
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TripFlight } from "@/components/FlightInfo/FlightInfoApi";
import { mdiStar } from "@mdi/js";

@Component({})
export default class TripFlightPrice extends Vue {
  @Prop()
  tripFlight?: TripFlight;
  @Prop()
  greenPriceLimit?: number;

  formatter = new Intl.NumberFormat();

  icons = {
    mdiStar: mdiStar,
  };

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
