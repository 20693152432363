
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import RyanairImages from "@/components/FlightInfo/RyanairImages.json";
import { TripAirport } from "@/components/FlightInfo/FlightInfoApi";

@Component({})
export default class AirportSum extends Vue {
  @Prop()
  tripAirport!: TripAirport;

  imageData: object = RyanairImages.destinationInformation;

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  formatter = new Intl.NumberFormat();
}
