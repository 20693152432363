
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class RyanairSearch extends Vue {
  @Prop()
  loading?: boolean;
  @Prop()
  year?: string;
  @Prop()
  month?: string;
  @Prop()
  minDays?: number;
  @Prop()
  maxDays?: number;

  months = [
    { value: "01", text: "Január" },
    { value: "02", text: "Február" },
    { value: "03", text: "Március" },
    { value: "04", text: "Április" },
    { value: "05", text: "Május" },
    { value: "06", text: "Június" },
    { value: "07", text: "Július" },
    { value: "08", text: "Augusztus" },
    { value: "09", text: "Szeptember" },
    { value: "10", text: "Október" },
    { value: "11", text: "November" },
    { value: "12", text: "December" },
  ];

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
