import { FlightInfoApi, TripFlight } from "@/components/FlightInfo/FlightInfoApi";

export class TripFlightsDb {
  private data = new Map<string, TripFlight[]>(); //key = destinationAirportIata

  clear() {
    this.data.clear();
  }

  add(destinationAirportIata: string, _tripFlights: TripFlight[]) {
    const key = destinationAirportIata;
    let tripFlights = this.data.get(key);
    if (!tripFlights) {
      tripFlights = [];
      this.data.set(key, tripFlights);
    }
    tripFlights.push(..._tripFlights);
  }

  private find(destinationAirportIata: string) {
    const key = destinationAirportIata;
    return this.data.get(key) ?? [];
  }

  getTripFlights(
    destinationAirportIata: string,
    selectedDays: number | null,
    isDirectOnly: boolean,
    selectedDate: { date: string } | null,
    limit: number
  ) {
    const results: TripFlight[] = [];
    const tripFlights = this.find(destinationAirportIata);
    for (const tripFlight of tripFlights ?? []) {
      if (selectedDays && selectedDays !== tripFlight.days) {
        continue;
      }
      if (isDirectOnly && !tripFlight.directBothWay) {
        continue;
      }

      if (selectedDate) {
        const days1 = Math.trunc(tripFlight.outOneWay.departureDate / FlightInfoApi.oneDay);
        const days2 = Math.trunc(Date.parse(selectedDate.date) / FlightInfoApi.oneDay);
        if (days1 !== days2) {
          continue;
        }
      }
      results.push(tripFlight);
    }

    return results.slice(0, limit);
  }

  getCheapestByDepartureDate(destinationAirportIata: string, selectedDays: number | null, isDirectOnly: boolean) {
    const cheapestMap = new Map<number, TripFlight>();
    const tripFlights = this.find(destinationAirportIata);
    for (const tripFlight of tripFlights ?? []) {
      if (selectedDays && selectedDays !== tripFlight.days) {
        continue;
      }
      if (isDirectOnly && !tripFlight.directBothWay) {
        continue;
      }

      const key = Math.trunc(tripFlight.outOneWay.departureDate / FlightInfoApi.oneDay);
      const cheapest1 = cheapestMap.get(key);
      // noinspection JSIncompatibleTypesComparison
      if (cheapest1 === undefined || tripFlight.sumPrice < cheapest1.sumPrice) {
        cheapestMap.set(key, tripFlight);
      }
    }

    const cheapestTripFlights = [...cheapestMap.values()];
    for (const tripFlight of cheapestTripFlights) {
      tripFlight.outOneWayDepartureDate = tripFlight.outOneWay.departureDate;
    }

    cheapestTripFlights.sort((a, b) => {
      return b.sumPrice < a.sumPrice ? 1 : -1;
    });

    return cheapestTripFlights;
  }

  getCheapestByDays(destinationAirportIata: string) {
    const cheapestMap = new Map<number, TripFlight>();
    const tripFlights = this.find(destinationAirportIata);
    for (const tripFlight of tripFlights ?? []) {
      const key = tripFlight.days;
      const cheapest = cheapestMap.get(key);
      // noinspection JSIncompatibleTypesComparison
      if (cheapest === undefined || tripFlight.sumPrice < cheapest.sumPrice) {
        cheapestMap.set(key, tripFlight);
      }
    }
    const cheapestTripFlights = [...cheapestMap.values()];
    cheapestTripFlights.sort((a, b) => {
      return b.days < a.days ? 1 : -1;
    });

    return cheapestTripFlights;
  }
}
