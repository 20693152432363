
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Flight, FlightInfoApi, FlightStop } from "@/components/FlightInfo/FlightInfoApi";
import dayjs from "dayjs";

@Component({
  components: {},
})
export default class TripFlights extends Vue {
  @Prop()
  flights?: Flight[];

  @Prop()
  stops?: FlightStop[];

  formatter = new Intl.NumberFormat();

  formatDate(date: number) {
    return dayjs(date).format(FlightInfoApi.dateFormat);
  }

  formatDateTime(date: number) {
    return dayjs(date).format(FlightInfoApi.dateFormatTime);
  }

  getButtonStyle(airline: string) {
    if (airline === "Wizz air") {
      return "background-color: #C02B86 !important;";
    }
    if (airline === "Ryanair") {
      return "background-color: #F4CC34 !important; color: #073590 !important";
    }
  }

  getWebsiteUrl(flight: Flight) {
    return flight.api.getWebsiteUrl(flight.departureAirport.iata, flight.arrivalAirport.iata, flight.departureDate);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
