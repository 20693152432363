import dayjs from "dayjs";
import { Airport, Flight, FlightInfoApi } from "@/components/FlightInfo/FlightInfoApi";
import firebase from "@/plugins/firebase";

//Elérhető időpontok
//https://services-api.ryanair.com/farfnd/3/oneWayFares/BUD/BLQ/availabilities

export class RyanairApi extends FlightInfoApi {
  airline = "Ryanair";

  getWebsiteUrl(fromIata: string, toIata: string, from: number) {
    const dateFormat = "YYYY-MM-DD";
    const fromDate = dayjs(from).format(dateFormat);
    return (
      "https://www.ryanair.com/hu/hu/trip/flights/select?adults=1&teens=0&children=0&infants=0" +
      "&dateOut=" +
      fromDate +
      "&dateIn=" +
      "" +
      "&isConnectedFlight=false&isReturn=true&discount=0&promoCode=" +
      "&originIata=" +
      fromIata +
      "&destinationIata=" +
      toIata +
      "&tpAdults=1&tpTeens=0&tpChildren=0&tpInfants=0" +
      "&tpStartDate=" +
      fromDate +
      "&tpEndDate=" +
      "" +
      "&tpDiscount=0&tpPromoCode=&tpOriginIata=" +
      fromIata +
      "&tpDestinationIata=" +
      toIata
    );
  }
  async getReturnFlights(departureAirport: Airport, arrivalAirport: Airport, fromDate: string) {
    return {
      fromFlights: await this.getFlights(departureAirport, arrivalAirport, fromDate),
      toFlights: await this.getFlights(arrivalAirport, departureAirport, fromDate),
    };
  }
  private async getFlights(departureAirport: Airport, arrivalAirport: Airport, fromDate: string): Promise<Flight[]> {
    const idToken = (await firebase.getIdToken()) ?? "";

    let data: any;
    try {
      const body = {
        departureAirportIata: departureAirport.iata,
        arrivalAirportIata: arrivalAirport.iata,
        fromDate: fromDate,
      };
      const response = await fetch("/flight-info-api/ryanair/oneWayFares", {
        headers: {
          "x-auth-token": idToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        method: "POST",
      });
      data = await response.json();

      // const response = await fetch(
      //   "https://services-api.ryanair.com/farfnd/3/oneWayFares/" +
      //     departureAirport.iata +
      //     "/" +
      //     arrivalAirport.iata +
      //     "/cheapestPerDay?outboundMonthOfDate=" +
      //     fromDate
      // );
      // data = await response.json();
      this.backOffTime = undefined;
    } catch (e) {
      this.backOffTime = this.backOffTime !== undefined ? this.backOffTime * 2 : this.backOffTimeStart;
      console.log(this.airline, "wait", this.backOffTime);
      await this.wait(this.backOffTime);
      return await this.getFlights(departureAirport, arrivalAirport, fromDate);
    }
    const flights: Flight[] = [];
    for (const fare of data.outbound.fares) {
      if (fare.price === null) {
        continue;
      }
      let priceHuf = 100000;
      const price = fare.price.value;
      const currencyCode = fare.price.currencyCode;
      if (fare.price.currencyCode !== "HUF") {
        const rate = this.rates[currencyCode];
        if (rate !== undefined) {
          priceHuf = Math.round(price * rate);
        }
      } else {
        priceHuf = price;
      }
      const departureDate = Date.parse(fare.departureDate);
      const arrivalDate = Date.parse(fare.arrivalDate);
      const duration = this.duration(departureDate, arrivalDate);

      const flight: Flight = {
        api: this,
        departureDate,
        arrivalDate,
        departureAirport,
        arrivalAirport,
        duration,
        price: priceHuf,
      };
      flights.push(flight);
    }
    return flights;
  }

  async getAirports() {
    const airports1 = await this.getAirPorts1();
    const airports2 = await this.getAirPorts2();
    //console.log(this.airline, airports1, airports2);

    const airports: Airport[] = [];
    for (const airport1 of airports1) {
      const airport2 = airports2.find((airport2) => airport2.code === airport1.iataCode);
      const routes = airport1.routes
        .filter((route: string) => route.startsWith("airport:"))
        .map((route: string) => route.split(":")[1]);
      const allRoutes = new Map<string, string[]>();
      allRoutes.set(this.airline, routes);

      const airport: Airport = {
        iata: airport1.iataCode,
        name: airport1.name,
        cityName: airport2.city.name,
        countryCode: airport1.countryCode.toUpperCase(),
        countryName: airport2.country.name,
        //latitude: airport1.coordinates.latitude,
        //longitude: airport1.coordinates.longitude,
        allRoutes,
      };
      airports.push(airport);
    }
    return airports;
  }

  private async getAirPorts1() {
    const url = "https://www.ryanair.com/api/locate/3/airports?market=hu-hu";
    const result = await fetch(url);
    const airports: any[] = await result.json();
    return airports;
  }

  private async getAirPorts2() {
    const url =
      "https://www.ryanair.com/api/locate/v5/airports?phrase=&market=hu-hu&fields=code&fields=name&fields=seoName&fields=base&fields=timeZone&fields=aliases&fields=city.code&fields=city.name&fields=coordinates.latitude&fields=coordinates.longitude&fields=macCity.code&fields=macCity.name&fields=macCity.macCode&fields=region.code&fields=region.name&fields=country.code&fields=country.name&fields=country.currency&fields=base";
    const result = await fetch(url);
    const airports: any[] = await result.json();
    return airports;
  }
}
