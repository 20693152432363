import { render, staticRenderFns } from "./CountrySum.vue?vue&type=template&id=675e3ee8&scoped=true&"
import script from "./CountrySum.vue?vue&type=script&lang=ts&"
export * from "./CountrySum.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "675e3ee8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VImg,VListItem,VListItemAvatar,VListItemContent})
