export interface Airport {
  iata: string;
  name: string;
  cityName: string;
  countryCode: string;
  countryName: string;
  //latitude: number;
  //longitude: number;
  allRoutes: Map<string, string[]>;
}

export interface FlightStop {
  stopAirportIata: string;
  duration: string[];
}

export interface Flight {
  api: FlightInfoApi;
  departureDate: number;
  arrivalDate: number;
  departureAirport: Airport;
  arrivalAirport: Airport;
  duration: string[];
  price: number;
}

export interface TripFlightOneWay {
  connectedFlights: Flight[];
  stops: FlightStop[];
  departureDate: number;
  arrivalDate: number;
  duration: string[];
  departureAirportIata: string;
  arrivalAirportIata: string;
  airlines: string[];
  sumPrice: number;
  direct: boolean;
}

export interface TripFlight {
  uuid?: string; //NOTE: We calculate it lazy on the template
  outOneWay: TripFlightOneWay;
  returnOneWay: TripFlightOneWay;
  outOneWayDepartureDate?: number; //NOTE: We calculate it lazy on the template
  days: number;
  sumPrice: number;
  directBothWay: boolean;
}

export interface TripAirport {
  airport: Airport;
  cheapestByDepartureDate?: TripFlight[]; //NOTE: We calculate it lazy on the template
  cheapestByDays?: TripFlight[]; //NOTE: We calculate it lazy on the template
  lowestSumPrice: number;
}

export interface TripCountry {
  countryCode: string;
  countryName: string;
  tripAirports: TripAirport[];
  lowestSumPrice: number;
}

export abstract class FlightInfoApi {
  abstract airline: string;
  protected backOffTimeStart = 2000;
  backOffTime?: number;

  protected rates: { [currencyCode: string]: number } = {
    EUR: 395,
    PLN: 83.5,
    SEK: 37.8,
    GBP: 466.56,
    CZK: 16.06,
    DKK: 52.9,
  };

  static dateFormatTime = "HH:mm";
  static dateFormat = "MMM DD. HH:mm";
  static dateFormatShort = "MMM D";
  static oneDay = 86400000;
  static oneHour = 3600000;
  static oneMin = 60000;

  duration(date1: number, date2?: number) {
    if (date2 === undefined) {
      return ["-"];
    }
    const duration = date2 - date1;
    const hours = Math.trunc(duration / FlightInfoApi.oneHour);
    const minutes = Math.trunc((duration - hours * FlightInfoApi.oneHour) / FlightInfoApi.oneMin);
    const result = [hours + " óra"];
    if (minutes > 0) {
      result.push(minutes + " perc");
    }
    return result;
  }

  abstract getWebsiteUrl(fromIata: string, toIata: string, from: number): string;

  abstract getReturnFlights(
    departureAirport: Airport,
    arrivalAirport: Airport,
    fromDate: string
  ): Promise<{ fromFlights: Flight[]; toFlights: Flight[] }>;

  async wait(waitMs: number) {
    return await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, waitMs);
    });
  }
}
