
import Vue from "vue";
import Component from "vue-class-component";
import RyanairImages from "@/components/FlightInfo/RyanairImages.json";
import SearchForm from "@/components/FlightInfo/SearchForm.vue";
import { RyanairApi } from "@/components/FlightInfo/RyanairApi";
import { WizzAirApi } from "@/components/FlightInfo/WizzAirApi";
import { Airport, FlightInfoApi, TripCountry } from "@/components/FlightInfo/FlightInfoApi";
import TripFlightList from "@/components/FlightInfo/TripFlight/TripFlightList";
import { FlightsDb } from "@/components/FlightInfo/FlightsDb";
import { AirportDb } from "@/components/FlightInfo/AirportDb";
import { RouteCalculator, RouteForApi } from "@/components/FlightInfo/RouteCalculator";
import { TripFlightsDb } from "@/components/FlightInfo/TripFlightsDb";
import { TripCountryManager } from "@/components/FlightInfo/TripCountryManager";
import CountrySum from "@/components/FlightInfo/CountrySum.vue";
import AirportSum from "@/components/FlightInfo/AirportSum.vue";
import dayjs from "dayjs";
require("dayjs/locale/hu");

@Component({
  components: { AirportSum, CountrySum, TripFlightList, SearchForm },
})
export default class FlightInfo extends Vue {
  apis = [new RyanairApi(), new WizzAirApi()];

  flightsDb = new FlightsDb();
  airportDb = new AirportDb();
  tripFlightsDb = new TripFlightsDb();

  imageData: object = RyanairImages.destinationInformation;
  private tripCountries: TripCountry[] = [];
  selectedAirportIata: string | null = null;
  selectedCountryName: string | null = null;
  loading = false;
  progress = 0;
  year = "2022";
  month = "10";
  minDays = 3;
  maxDays = 5;
  selectedDays: number | null = null;
  isDirectOnly = false;
  countryCodes = ["ES", "MV"]; //For indirect routes
  minStopHours = 1;
  maxStopHours = 24;
  greenPriceVariation = 1.15;

  getFrom() {
    return this.year + "-" + this.month + "-01";
  }

  async loadAirports() {
    const promises: Promise<void>[] = [];
    this.airportDb.clear();
    for (const api of this.apis) {
      const promise = api.getAirports().then((airports) => {
        this.airportDb.addMany(api.airline, airports);
      });
      promises.push(promise);
    }
    return Promise.all(promises);
  }

  async getData() {
    this.loading = true;
    this.selectedAirportIata = null;
    this.progress = 0;
    this.tripCountries = [];
    this.flightsDb.clear();

    await this.loadAirports();

    const routeCalculator = new RouteCalculator(this.airportDb);

    const promises = [];
    let routeCount = 0;
    let processedRoutes = 0;
    for (const api of this.apis) {
      const routes = routeCalculator.getRoutesForApi(api.airline, this.countryCodes);
      //console.log(
      //  "API routes",
      //  routes.map((route) => route.departureAirport.iata + "_" + route.arrivalAirport.iata).join("\n")
      //);
      routeCount += routes.length;
      const promise = this.getDataByApi(api, routes, () => {
        processedRoutes++;
        this.progress = (processedRoutes / routeCount) * 100;
      });
      promises.push(promise);
    }
    await Promise.all(promises);
    this.refresh();

    this.loading = false;
  }

  async getDataByApi(api: FlightInfoApi, routes: RouteForApi[], progressFnc: () => void) {
    api.backOffTime = undefined;
    for (const route of routes) {
      await this.getReturnPrices(api, route.departureAirport, route.arrivalAirport);
      //console.log(api.airline, route.departureIata, route.arrivalIata);
      progressFnc();
      if (!this.loading) {
        this.refresh();
        break;
      }
    }
  }

  async getReturnPrices(api: FlightInfoApi, departureAirport: Airport, arrivalAirport: Airport) {
    const returnFlights = await api.getReturnFlights(departureAirport, arrivalAirport, this.getFrom());
    if (returnFlights.fromFlights.length === 0 || returnFlights.toFlights.length === 0) {
      return false;
    }

    //console.log(departureAirport.iata, arrivalAirport.iata, returnFlights.fromFlights, returnFlights.toFlights);
    this.flightsDb.add(departureAirport.iata, arrivalAirport.iata, returnFlights.fromFlights);
    this.flightsDb.add(arrivalAirport.iata, departureAirport.iata, returnFlights.toFlights);
    return true;
  }

  refresh() {
    const start = Date.now();
    this.tripCountries = [];
    this.tripFlightsDb.clear();
    const routeCalculator = new RouteCalculator(this.airportDb);
    const tripCountryManager = new TripCountryManager();
    const routes = routeCalculator.getRoutes(this.countryCodes);
    //console.log("Refresh routes", routes);

    const from = Date.parse(this.getFrom());
    const to = dayjs(from).add(1, "month").valueOf();
    //console.log("dates", new Date(from), new Date(to));

    for (const [destAirportIata, routesItem] of routes.entries()) {
      const destAirport = this.airportDb.findOne(destAirportIata);
      if (!destAirport) {
        continue;
      }

      const tripFlights = this.flightsDb.createTripFlights(
        routesItem,
        this.minStopHours,
        this.maxStopHours,
        this.minDays,
        this.maxDays,
        from,
        to
      );
      if (tripFlights.length === 0) {
        continue;
      }

      tripFlights.sort((a, b) => {
        return b.sumPrice < a.sumPrice ? 1 : -1;
      });

      this.tripFlightsDb.add(destAirportIata, tripFlights);
      const lowestSumPrice = tripFlights[0].sumPrice;

      tripCountryManager.createOrUpdate(this.tripCountries, destAirport, lowestSumPrice);
    }

    tripCountryManager.sort(this.tripCountries);

    const diff = Date.now() - start;
    console.log("TOTAL", diff + "ms");
  }

  clickCountry(countryName: string) {
    if (this.selectedCountryName !== countryName) {
      this.selectedCountryName = countryName;
    } else {
      this.selectedCountryName = null;
    }
  }

  clickAirport(airport: Airport) {
    if (this.selectedAirportIata !== airport.iata) {
      this.selectedAirportIata = airport.iata;
    } else {
      this.selectedAirportIata = null;
    }
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
