import { Airport, TripAirport, TripCountry } from "@/components/FlightInfo/FlightInfoApi";

export class TripCountryManager {
  createOrUpdate(tripCountries: TripCountry[], destAirport: Airport, lowestSumPrice: number) {
    const existingTripCountry = tripCountries.find(
      (tripCountry) => tripCountry.countryName === destAirport.countryName
    );
    if (existingTripCountry) {
      if (lowestSumPrice < existingTripCountry.lowestSumPrice) {
        existingTripCountry.lowestSumPrice = lowestSumPrice;
      }
      const existingTripAirport = existingTripCountry.tripAirports.find(
        (tripAirport) => tripAirport.airport.iata === destAirport.iata
      );
      if (existingTripAirport) {
        if (lowestSumPrice < existingTripAirport.lowestSumPrice) {
          existingTripAirport.lowestSumPrice = lowestSumPrice;
        }
      } else {
        const tripAirport: TripAirport = { lowestSumPrice: lowestSumPrice, airport: destAirport };
        existingTripCountry.tripAirports.push(tripAirport);
      }
      this.sortAirports(existingTripCountry.tripAirports);
    } else {
      tripCountries.push({
        countryCode: destAirport.countryCode,
        countryName: destAirport.countryName,
        tripAirports: [{ airport: destAirport, lowestSumPrice: lowestSumPrice }],
        lowestSumPrice,
      });
    }
  }

  sort(tripCountries: TripCountry[]) {
    tripCountries.sort((a, b) => {
      return b.lowestSumPrice < a.lowestSumPrice
        ? 1
        : b.lowestSumPrice > a.lowestSumPrice
        ? -1
        : b.countryName < a.countryName
        ? 1
        : -1;
    });
  }

  private sortAirports(tripAirports: TripAirport[]) {
    tripAirports.sort((a, b) => {
      return b.lowestSumPrice < a.lowestSumPrice
        ? 1
        : b.lowestSumPrice > a.lowestSumPrice
        ? -1
        : b.airport.name < a.airport.name
        ? 1
        : -1;
    });
  }
}
