
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TripFlight } from "@/components/FlightInfo/FlightInfoApi";

@Component({
  components: {},
})
export default class TripFlightCalendar extends Vue {
  @Prop()
  from?: string;
  @Prop()
  cheapestByDepartureDate?: TripFlight[];
  @Prop()
  greenPriceLimit?: number;
  @Prop()
  selectedDate?: { date: string; day: number } | null;

  shortPrice(price: number) {
    return Math.round(price / 1000);
  }

  selectDay(day: any) {
    const value = this.selectedDate && this.selectedDate.date === day.week[day.index].date ? null : day.week[day.index];
    this.$emit("update:selectedDate", value);
  }
}
