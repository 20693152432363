
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DesktopHu from "@/components/FlightInfo/DesktopHu.json";
import { TripCountry } from "@/components/FlightInfo/FlightInfoApi";

@Component({})
export default class CountrySum extends Vue {
  @Prop()
  tripCountry!: TripCountry;

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  formatter = new Intl.NumberFormat();

  getImageSrc() {
    return DesktopHu.components[0].countryImagePattern.url3x.replace(
      "{{country}}",
      this.tripCountry.countryCode.toLowerCase()
    );
  }

  getDescription() {
    //@ts-ignore
    return DesktopHu.components[0].descriptions.countries[this.tripCountry.countryCode];
  }
}
