
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { FlightInfoApi, TripAirport, TripFlight } from "@/components/FlightInfo/FlightInfoApi";
import FlightInfo from "@/components/FlightInfo/FlightInfo.vue";
import FlightIcon from "@/components/FlightInfo/FlightIcon.vue";
import { mdiAirplaneTakeoff, mdiCloseCircle, mdiWeatherSunny } from "@mdi/js";
import TripFlightSummary from "@/components/FlightInfo/TripFlight/TripFlightSummary.vue";
import TripFlightCalendar from "@/components/FlightInfo/TripFlight/TripFlightCalendar.vue";
import TripFlightTimeline from "@/components/FlightInfo/TripFlight/TripFlightTimeline.vue";
import TripFlightChipFilter from "@/components/FlightInfo/TripFlight/TripFlightChipFilter.vue";
import dayjs from "dayjs";
import TripFlightPrice from "@/components/FlightInfo/TripFlight/TripFlightPrice.vue";
import { TripFlightsDb } from "@/components/FlightInfo/TripFlightsDb";

@Component({
  components: {
    TripFlightPrice,
    TripFlightChipFilter,
    TripFlightTimeline,
    TripFlightCalendar,
    TripFlightSummary,
    FlightIcon,
    FlightInfo,
  },
})
export default class TripFlightList extends Vue {
  @Prop()
  tripAirport!: TripAirport;
  @Prop()
  tripFlightsDb!: TripFlightsDb;
  @Prop()
  minDays?: number;
  @Prop()
  maxDays?: number;
  @Prop()
  greenPriceVariation!: number;
  @Prop()
  selectedDays!: number | null;
  @Prop()
  isDirectOnly!: boolean;
  @Prop()
  from!: string;

  selectedDate: { date: string; day: number } | null = null;
  cheapestByDays: TripFlight[] = [];
  cheapestByDepartureDate: TripFlight[] = [];
  greenPriceLimit = 0;

  tripFlights: TripFlight[] = [];

  selectedTripFlight: TripFlight | null = null;
  limit = 20;

  icons = {
    mdiCloseCircle: mdiCloseCircle,
    mdiWeatherSunny: mdiWeatherSunny,
    mdiAirplaneTakeoff: mdiAirplaneTakeoff,
  };

  formatter = new Intl.NumberFormat();

  created() {
    this.cheapestByDepartureDate = this.getCheapestByDepartureDate();
    this.cheapestByDays = this.getCheapestByDays();
    this.tripFlights = this.getTripFlights();
  }

  @Watch("selectedDays")
  @Watch("isDirectOnly")
  onChangeSelectedDays() {
    this.cheapestByDepartureDate = this.getCheapestByDepartureDate();
    this.tripFlights = this.getTripFlights();
  }

  @Watch("trip.tripFlights")
  onChangeTripFlights() {
    this.cheapestByDepartureDate = this.getCheapestByDepartureDate();
    this.cheapestByDays = this.getCheapestByDays();
    this.tripFlights = this.getTripFlights();
  }

  @Watch("selectedDate")
  onChangeSelectedDate() {
    this.tripFlights = this.getTripFlights();
  }

  clickTripFlight(tripFlight: TripFlight) {
    if (this.selectedTripFlight !== tripFlight) {
      this.selectedTripFlight = tripFlight;
    } else {
      this.selectedTripFlight = null;
    }
  }

  getTripFlights() {
    return this.tripFlightsDb.getTripFlights(
      this.tripAirport.airport.iata,
      this.selectedDays,
      this.isDirectOnly,
      this.selectedDate,
      this.limit
    );
  }

  private getCheapestByDepartureDate() {
    const cheapestTripFlights = this.tripFlightsDb.getCheapestByDepartureDate(
      this.tripAirport.airport.iata,
      this.selectedDays,
      this.isDirectOnly
    );
    this.greenPriceLimit = cheapestTripFlights.length ? cheapestTripFlights[0].sumPrice * this.greenPriceVariation : 0;
    return cheapestTripFlights;
  }

  private getCheapestByDays() {
    const cheapestTripFlights = this.tripFlightsDb.getCheapestByDays(this.tripAirport.airport.iata);
    if (
      this.selectedDays &&
      cheapestTripFlights.length &&
      !cheapestTripFlights.map((tripFlight) => tripFlight.days).includes(this.selectedDays)
    ) {
      this.$emit("update:selectedDays", null);
    }
    return cheapestTripFlights;
  }

  getTripFlightKey(tripFlight: TripFlight) {
    if (!tripFlight.uuid) {
      //@ts-ignore
      tripFlight.uuid = crypto.randomUUID();
    }
    return tripFlight.uuid;
  }

  formatDateShort(date: number) {
    return dayjs(date).locale("hu").format(FlightInfoApi.dateFormatShort);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
