import { Flight, FlightInfoApi, FlightStop, TripFlight, TripFlightOneWay } from "@/components/FlightInfo/FlightInfoApi";
import { RouteForProcess } from "@/components/FlightInfo/RouteCalculator";

export class FlightsDb {
  private flights = new Map<string, Flight[]>();

  clear() {
    this.flights.clear();
  }

  add(departureIata: string, arrivalIata: string, flights: Flight[]) {
    const key = departureIata + "_" + arrivalIata;
    let outFlights = this.flights.get(key);
    if (!outFlights) {
      outFlights = [];
      this.flights.set(key, outFlights);
    }
    outFlights.push(...flights);
    //TODO We need to overwrite the records from the same airport?
    //TODO Maybe only if the dates are the same?
  }

  createTripFlights(
    routesItem: RouteForProcess,
    minStopHours: number,
    maxStopHours: number,
    minDays: number,
    maxDays: number,
    from: number,
    to: number
  ) {
    const outCombinations = this.getCombinations(
      routesItem.departureIata,
      routesItem.interIatas,
      routesItem.arrivalIata,
      minStopHours,
      maxStopHours
    );
    const returnCombinations = this.getCombinations(
      routesItem.arrivalIata,
      routesItem.interIatas,
      routesItem.departureIata,
      minStopHours,
      maxStopHours
    );

    const tripFlights: TripFlight[] = [];

    for (const outCombination of outCombinations) {
      for (const returnCombination of returnCombinations) {
        const tripFlight = this.createTripFlight(
          outCombination.connectedFlights,
          returnCombination.connectedFlights,
          minDays,
          maxDays,
          from,
          to
        );
        if (tripFlight) {
          tripFlights.push(tripFlight);
        }
      }
    }

    return tripFlights;
  }

  private createTripFlight(
    outConnectedFlights: Flight[],
    returnConnectedFlights: Flight[],
    minDays: number,
    maxDays: number,
    from: number,
    to: number
  ) {
    const firstOutFlight = outConnectedFlights[0];
    const lastOutFlight = outConnectedFlights[outConnectedFlights.length - 1];
    const firstReturnFlight = returnConnectedFlights[0];
    const lastReturnFlight = returnConnectedFlights[returnConnectedFlights.length - 1];

    const outDepartureDate = firstOutFlight.departureDate;
    const outArrivalDate = lastOutFlight.arrivalDate;
    const returnDepartureDate = firstReturnFlight.departureDate;
    const returnArrivalDate = lastReturnFlight.arrivalDate;

    if (outDepartureDate < from || outDepartureDate >= to) {
      return;
    }

    const days = this.days(outDepartureDate, returnDepartureDate);
    if (days < minDays || days > maxDays) {
      return;
    }
    const outDuration = this.duration(outDepartureDate, outArrivalDate);
    const returnDuration = this.duration(returnDepartureDate, returnArrivalDate);

    const ourPrice = outConnectedFlights.reduce((sum, flight) => sum + flight.price, 0);
    const returnPrice = returnConnectedFlights.reduce((sum, flight) => sum + flight.price, 0);
    const sumPrice = ourPrice + returnPrice;

    const outStops = this.getStops(outConnectedFlights);
    const returnStops = this.getStops(returnConnectedFlights);

    const outOneWay: TripFlightOneWay = {
      connectedFlights: outConnectedFlights,
      stops: outStops,
      airlines: this.getAirlines(outConnectedFlights),
      departureDate: outDepartureDate,
      arrivalDate: outArrivalDate,
      duration: outDuration,
      departureAirportIata: firstOutFlight.departureAirport.iata,
      arrivalAirportIata: lastOutFlight.arrivalAirport.iata,
      sumPrice: ourPrice,
      direct: outStops.length === 0,
    };

    const returnOneWay: TripFlightOneWay = {
      connectedFlights: returnConnectedFlights,
      stops: returnStops,
      airlines: this.getAirlines(returnConnectedFlights),
      departureDate: returnDepartureDate,
      arrivalDate: returnArrivalDate,
      duration: returnDuration,
      departureAirportIata: firstReturnFlight.departureAirport.iata,
      arrivalAirportIata: lastReturnFlight.arrivalAirport.iata,
      sumPrice: returnPrice,
      direct: returnStops.length === 0,
    };

    const tripFlight: TripFlight = {
      outOneWay,
      returnOneWay,
      days,
      sumPrice,
      directBothWay: outOneWay.direct && returnOneWay.direct,
    };
    return tripFlight;
  }

  private getCombinations(
    departureIata: string,
    interIatas: string[],
    arrivalIata: string,
    minStopHours: number,
    maxStopHours: number
  ) {
    const combinations: { connectedFlights: Flight[] }[] = [];

    //Push direct flight
    this.find(departureIata, arrivalIata).forEach((flight) => {
      combinations.push({ connectedFlights: [flight] });
    });

    //Push indirect flights
    for (const interIata of interIatas) {
      const flights1 = this.find(departureIata, interIata);
      const flights2 = this.find(interIata, arrivalIata);
      for (const flight1 of flights1) {
        for (const flight2 of flights2) {
          const stopHours = this.hours(flight1.arrivalDate, flight2.departureDate);
          if (stopHours >= minStopHours && stopHours <= maxStopHours) {
            combinations.push({ connectedFlights: [flight1, flight2] });
          }
        }
      }
    }

    return combinations;
  }

  private getStops(flights: Flight[]) {
    const stops: FlightStop[] = [];
    if (flights.length > 0) {
      for (let i = 1; i < flights.length; i++) {
        const prev = flights[i - 1];
        const current = flights[i];
        stops.push({
          stopAirportIata: current.departureAirport.iata,
          duration: this.duration(prev.arrivalDate, current.departureDate),
        });
      }
    }
    return stops;
  }

  private getAirlines(flights: Flight[]) {
    const airlines = flights.map((flight) => flight.api.airline);
    return [...new Set(airlines)];
  }

  private duration(date1: number, date2?: number) {
    if (date2 === undefined) {
      return ["-"];
    }
    const duration = date2 - date1;
    const hours = Math.trunc(duration / FlightInfoApi.oneHour);
    const minutes = Math.trunc((duration - hours * FlightInfoApi.oneHour) / FlightInfoApi.oneMin);
    const result = [hours + " óra"];
    if (minutes > 0) {
      result.push(minutes + " perc");
    }
    return result;
  }

  private days(date1: number, date2: number) {
    const days1 = Math.trunc(date1 / FlightInfoApi.oneDay);
    const days2 = Math.trunc(date2 / FlightInfoApi.oneDay);

    return days2 - days1;
  }

  private hours(date1: number, date2: number) {
    const duration = date2 - date1;
    return duration / FlightInfoApi.oneHour;
  }

  private find(departureIata: string, arrivalIata: string) {
    const key = departureIata + "_" + arrivalIata;
    return this.flights.get(key) ?? [];
  }
}
