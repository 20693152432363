
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { FlightInfoApi, TripFlightOneWay } from "@/components/FlightInfo/FlightInfoApi";
import FlightInfo from "@/components/FlightInfo/FlightInfo.vue";
import FlightIcon from "@/components/FlightInfo/FlightIcon.vue";
import dayjs from "dayjs";

@Component({
  components: { FlightIcon, FlightInfo },
})
export default class TripFlightSummary extends Vue {
  @Prop()
  oneWay?: TripFlightOneWay;

  formatDateTime(date: number) {
    return dayjs(date).format(FlightInfoApi.dateFormatTime);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
