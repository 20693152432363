
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TripFlight } from "@/components/FlightInfo/FlightInfoApi";
import FlightInfo from "@/components/FlightInfo/FlightInfo.vue";
import FlightIcon from "@/components/FlightInfo/FlightIcon.vue";
import { mdiCloseCircle } from "@mdi/js";

@Component({
  components: { FlightIcon, FlightInfo },
})
export default class TripFlightChipFilter extends Vue {
  @Prop()
  selectedDays?: number | null;
  @Prop()
  isDirectOnly?: boolean;
  @Prop()
  selectedDate?: { date: string; day: number } | null;
  @Prop()
  cheapestByDays?: TripFlight[];

  icons = {
    mdiCloseCircle: mdiCloseCircle,
  };
}
