import { Airport } from "@/components/FlightInfo/FlightInfoApi";

export class AirportDb {
  airports = new Map<string, Airport>();

  addMany(airline: string, airports: Airport[]) {
    for (const airport of airports) {
      if (this.airports.has(airport.iata)) {
        const airportExisting = this.airports.get(airport.iata);
        airportExisting?.allRoutes.set(airline, airport.allRoutes.get(airline) ?? []);
      } else {
        this.airports.set(airport.iata, airport);
      }
    }
  }

  findAll() {
    return [...this.airports.values()];
  }

  findAllByCountryCode(countryCodes: string[]) {
    return this.findAll().filter((airport) => countryCodes.includes(airport.countryCode));
  }

  findAllIataByCountryCode(countryCodes: string[]) {
    return this.findAllByCountryCode(countryCodes).map((airport) => airport.iata);
  }

  findOne(iata: string) {
    return this.airports.get(iata);
  }

  clear() {
    this.airports.clear();
  }

  getBud(): Airport {
    return this.findOne("BUD") as Airport;
  }

  getRoutes(airport: Airport, airline: string) {
    return airport?.allRoutes.get(airline) ?? [];
  }

  getAllRoutes(airport: Airport) {
    const routes: string[] = [];
    for (const _routes of airport.allRoutes.values()) {
      routes.push(..._routes);
    }
    return routes;
  }

  getBudRoutes(airline: string) {
    return this.getRoutes(this.getBud(), airline);
  }

  getAllBudRoutes() {
    return this.getAllRoutes(this.getBud());
  }

  getInterAirports(baseAirport: Airport, airline: string) {
    return this.findAll().filter((airport) => this.getRoutes(baseAirport, airline).includes(airport.iata));
  }

  getBudInterAirports(airline: string) {
    return this.getInterAirports(this.getBud(), airline);
  }

  getAllInterAirports(baseAirport: Airport) {
    return this.findAll().filter((airport) => this.getAllRoutes(baseAirport).includes(airport.iata));
  }

  getAllBudInterAirports() {
    return this.getAllInterAirports(this.getBud());
  }
}
